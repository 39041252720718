import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const onLink = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    },2);
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://be.siedwebs.sk/footer-copy';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <>
        {/*=====================================*/}
        {/*=        Footer Area Start       	=*/}
        {/*=====================================*/}
        <footer className="footer-area">
          <div className="container">
            <div className="footer-top">
              <div className="footer-social-link col-12 col-md-5 col-lg-4 col-lg-3">
                <ul className="list-unstyled">
                  <li><a href="https://www.facebook.com/siedwebs" aria-label="Facebook" data-sal="slide-up" data-sal-duration={500} data-sal-delay={100}><i className="fab fa-facebook-f" /><span className='sr-only'>SIEDwebs facebook</span></a></li>
                  <li><a href="https://www.linkedin.com/company/siedwebs" aria-label="LinkedIn" data-sal="slide-up" data-sal-duration={500} data-sal-delay={400}><i className="fab fa-linkedin-in" /><span className='sr-only'>SIEDwebs LinkedIn</span></a></li>
                  <li><a href="https://www.instagram.com/siedwebs/" aria-label="Instagram" data-sal="slide-up" data-sal-duration={500} data-sal-delay={500}><i className="fab fa-instagram" /><span className='sr-only'>SIEDwebs Instagram</span></a></li>
                </ul>
              </div>
            </div>
            <div className="footer-main">
              <div className="row">
                <div className="col-xl-12 col-lg-12" data-sal="slide-up" data-sal-duration={800} data-sal-delay={100}>
                  <div className="row justify-content-sm-center">
                    <div className="ss">
                      <div className="footer-widget">
                        <div className="footer-menu-link">
                          <ul className="list-unstyled">
                          <li><Link to="/" onClick={onLink}>Home</Link></li>
                          <li><Link to="/about-us" onClick={onLink}>About us</Link></li>
                          <li><Link to="/pricing" onClick={onLink}>Pricing</Link></li>
                          <li><Link to="/contact" onClick={onLink}>Contact</Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom" data-sal="slide-up" data-sal-duration={500} data-sal-delay={100}>
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-copyright">
                    <span className="copyright-text" id="sied-copy"></span>
                    {/* <span className="copyright-text">© 2023. All rights reserved by <Link to="/" onClick={onLink}>SIED</Link>.</span> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-bottom-link">
                    <ul className="list-unstyled">
                      <li><Link to="/privacy-policy" onClick={onLink}>Privacy Policy</Link></li>
                      {/* <li><a href="https://www.iubenda.com/privacy-policy/62353408/legal" aria-label="Bedingungen und Konditionen" target="_blank" rel="noreferrer">Bedingungen und Konditionen</a></li> */}
                      <li><Link to="/terms-and-conditions"  onClick={onLink}>Terms & Conditions</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*=====================================*/}
        {/*=       Offcanvas Menu Area       	=*/}
        {/*=====================================*/}
        {/* <div className="offcanvas offcanvas-end header-offcanvasmenu" tabIndex={-1} id="offcanvasMenuRight">
          <div className="offcanvas-header">
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
          </div>
          <div className="offcanvas-body">
            <form action="#" className="side-nav-search-form">
              <div className="form-group">
                <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                <button className="side-nav-search-btn"><i className="fas fa-search" /></button>
              </div>
            </form>
            <div className="row ">
              <div className="col-lg-5 col-xl-6">
                <ul className="main-navigation list-unstyled">
                  <li><a href="index-1.html">Digital Agency</a></li>
                  <li><a href="index-2.html">Creative Agency</a></li>
                  <li><a href="index-3.html">Personal Portfolio</a></li>
                  <li><a href="index-4.html">Home Startup</a></li>
                  <li><a href="index-5.html">Corporate Agency</a></li>
                </ul>
              </div>
              <div className="col-lg-7 col-xl-6">
                <div className="contact-info-wrap">
                  <div className="contact-inner">
                    <address className="address">
                      <span className="title">Contact Information</span>
                      <p>Theodore Lowe, Ap #867-859 <br /> Sit Rd, Azusa New York</p>
                    </address>
                    <address className="address">
                      <span className="title">We're Available 24/7. Call Now.</span>
                      <a className="tel" href="tel:8884562790"><i className="fas fa-phone" />(888)
                        456-2790</a>
                      <a className="tel" href="tel:12125553333"><i className="fas fa-fax" />(121)
                        255-53333</a>
                    </address>
                  </div>
                  <div className="contact-inner">
                    <h5 className="title">Find us here</h5>
                    <div className="contact-social-share">
                      <ul className="social-share list-unstyled">
                        <li><a href="https://facebook.com/"><i className="fab fa-facebook-f" /></a></li>
                        <li><a href="https://twitter.com/"><i className="fab fa-twitter" /></a></li>
                        <li><a href="https://www.behance.net/"><i className="fab fa-behance" /></a></li>
                        <li><a href="https://www.linkedin.com/"><i className="fab fa-linkedin-in" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
  </>;
}
