import React, {useState, useEffect, useCallback} from 'react';
import TagManager from 'react-gtm-module/dist/TagManager';
import { useMediaQuery } from 'react-responsive'

// import ThemeSwitcher from '../components/ThemeSwitcher';
import { Link, useLocation } from 'react-router-dom';

import { InlineWidget } from 'react-calendly';

// import { GTMProvider, useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

export default function Header() {

  const [isActive, setIsActive] = useState(false);
  const toggle = () => setIsActive(!isActive);
  useEffect(() => {
    if(isActive) {
          document.querySelector("body").style.overflow = 'hidden';
      } else {
          document.querySelector('body').removeAttribute("style");
      }
  }, [isActive]);

  const [isMenuOpen, setisMenuOpen] = useState(false);

  const toggleMenu = () => setisMenuOpen(!isMenuOpen);

  useEffect(() => {
    if(isMenuOpen) {
      document.querySelector("#mobilemenu-popup").classList.remove('offcanvas');
      document.querySelector(".offcanvas-backdrop").classList.remove('offcanvas');
      document.querySelector("#mobilemenu-popup").classList.add('show');
      document.querySelector(".offcanvas-backdrop").classList.add('show');
      document.querySelector("body").style.overflow = 'hidden';
    } else {
      document.querySelector("#mobilemenu-popup").classList.add('offcanvas');
      document.querySelector(".offcanvas-backdrop").classList.add('offcanvas');
      document.querySelector("#mobilemenu-popup").classList.remove('show');
      document.querySelector(".offcanvas-backdrop").classList.remove('show');
      document.querySelector('body').removeAttribute("style");
    }
  }, [isMenuOpen]);

  const {pathname} = useLocation();
  useEffect(() => {
    setisMenuOpen(false);
  }, [pathname]);

  const handleMediaQueryChange = (matches) => {
    if(matches) {
      document.querySelector("body").classList.remove('mobilemenu-active');
      document.querySelector("#mobilemenu-popup").classList.remove('offcanvas');
      document.querySelector("#mobilemenu-popup").classList.remove('show');
      document.querySelector("#mobilemenu-popup").removeAttribute('style');
      // document.querySelector(".axil-submenu").removeAttribute('style');
    } else {
      document.querySelector("body").classList.add('mobilemenu-active');
      document.querySelector("#mobilemenu-popup").classList.add('offcanvas');
    }
  }
  useEffect(() => {
    document.querySelector("#mobilemenu-popup").classList.remove('offcanvas');
  }, []);
  useMediaQuery(
    { minWidth: 992 }, undefined,  handleMediaQueryChange
  );

  const [isModalActive, setIsModalActive] = useState(false);
  const toggleModal = () => setIsModalActive(!isModalActive);
  useEffect(() => {
    if(isModalActive) {
          document.querySelector("body").style.overflow = 'hidden';
      } else {
          document.querySelector('body').removeAttribute("style");
      }
  }, [isModalActive]);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      if(isActive)
        setIsActive(!isActive);
      if(isMenuOpen)
        setisMenuOpen(!isMenuOpen);
      if(isModalActive)
        setIsModalActive(!isModalActive);
    }
  }, [isActive, isMenuOpen, isModalActive]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [msg, setMsg] = useState('');
  const [submitButtonText, setSubmitButtonText] = useState('Odoslať');
  const [formSucc, setFormSucc] = useState(true);


  // const gtmParams = {
  //   id: 'GTM-KWNVL8X',
  //   dataLayerName: 'GTM-KWNVL8X',
  // }
  // const sendDataToGTM = useGTMDispatch();
  // const tagManagerArgs = {
  //   gtmId: 'GTM-KWNVL8X'
  // }
  
  // TagManager.initialize(tagManagerArgs)

  const handleSubmit = (e) => {
    e.preventDefault();
    let product = "";
    const inputs = { name, email, tel, product, msg };
    setSubmitButtonText('Odosiela sa...');
    fetch('https://be.siedwebs.sk/api/send-pricing', {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(inputs)
    }).then((response) => {
      setSubmitButtonText('Odoslať');
      setIsActive(false);
      setName('');
      setEmail('');
      setTel('');
      setMsg('');
      if(response.ok) {
        setFormSucc(true);
      } else {
        setFormSucc(false);
      }
    })
    fetch('https://be.siedwebs.sk/api/send-pricing-reply', {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(inputs)
    }).then((response) => {
      setSubmitButtonText('Odoslať');
      setIsActive(false);
      setName('');
      setEmail('');
      setTel('');
      setMsg('');
      if(response.ok) {
        setFormSucc(true);
      } else {
        setFormSucc(false);
      }
      setIsModalActive(true);
    })
    // sendDataToGTM({event: 'headerStartProject', formName: 'header start project'});
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: "test"
    //   }
    // });
    // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
    // const tagManagerArgs = {
    //   dataLayer: {
    //       'js': new Date(), // current date time,
    //       event: 'headerStartProject',
    //   }
    // }
    // TagManager.dataLayer(tagManagerArgs);
  }

  const onLink = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    },2);
    setisMenuOpen(false);
  }

  return <>
        {/*=====================================*/}
        {/*=        Header Area Start       	=*/}
        {/*=====================================*/}
          <header className={`header axil-header ${useLocation().pathname === "/" ? "header-style-4" : "header-style-1"} `}>
          <div id="axil-sticky-placeholder" />
          <div className="axil-mainmenu">
            <div className="container">
              <div className="header-navbar">
                <div className="header-logo">
                  {useLocation().pathname === "/" ?
                    <Link to="/" onClick={onLink}><img loading="eager" height="" width="" src="assets/media/sied/logo_white.svg" alt="logo" title="SIEDwebs logo"  /></Link>
                   :
                   <>
                  <Link to="/" onClick={onLink}><img loading="eager" height="" width=""class="light-version-logo" src="assets/media/sied/logo_black.svg" alt="logo" title="SIEDwebs logo" /></Link>
                  <Link to="/" onClick={onLink}><img loading="eager" height="" width=""class="dark-version-logo" src="assets/media/sied/logo_white.svg" alt="logo" title="SIEDwebs logo" /></Link>
                  <Link to="/" onClick={onLink}><img loading="eager" height="" width=""class="sticky-logo" src="assets/media/sied/logo_black.svg" alt="logo" title="SIEDwebs logo" /></Link>
                   </>
                  }
                </div>
                <div className="header-main-nav">
                  {/* Start Mainmanu Nav */}
                  <nav className="mainmenu-nav" id="mobilemenu-popup">
                    <div className="d-block d-lg-none">
                      <div className="mobile-nav-header">
                        <div className="mobile-nav-logo">
                          <Link to="/" onClick={onLink}>
                            <img loading="eager" height="" width=""className="light-mode" src="assets/media/sied/logo_black.svg" alt="logo" title="SIEDwebs logo" />
                            <img loading="eager" height="" width=""className="dark-mode" src="assets/media/sied/logo_white.svg" alt="logo" title="SIEDwebs logo" />
                          </Link>
                        </div>
                        <button aria-label="Close menu" onClick={toggleMenu} className="mobile-menu-close"><i className="fas fa-times" /></button>
                      </div>
                    </div>
                    <ul className="mainmenu">
                      <li><Link to="/" onClick={onLink}>Home</Link></li>
                      <li><Link to="/about-us" onClick={onLink}>About us</Link></li>
                      <li><Link to="/pricing" onClick={onLink}>Pricing</Link></li>
                      <li><Link to="/contact" onClick={onLink}>Contact</Link></li>
                    </ul>
                  </nav>
                  <div onClick={toggleMenu} class="offcanvas-backdrop offcanvas"></div>
                  {/* End Mainmanu Nav */}
                </div>
                <div className="header-action">
                  <ul className="list-unstyled">
                    {
                      useLocation().pathname === "/" ?
                    <li className="header-btn hidden">
                      <span onClick={toggle} className="axil-btn btn-fill-white green-hover">Start Project</span>
                    </li>
                    :
                    <></>
                    }
                    <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                      <button aria-label="Open menu" onClick={toggleMenu} className="btn-wrap btn-dark" 
                      >
                        {/* data-bs-toggle="offcanvas"  */}
                        {/* data-bs-target="#mobilemenu-popup" */}
                        <span />
                        <span />
                        <span />
                      </button>
                    </li>
                    {/* <li className="my_switcher d-block d-lg-none"> */}
                      {/* <ThemeSwitcher></ThemeSwitcher> */}
                    {/* </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={`pric-form`}>
                        <div className={`form-div ${isActive ? "show" : ""}`}>
                            <div onClick={toggle} className={`pric-form-close ${isActive ? "show" : ""}`}><button aria-label="Close"><i className="fas fa-times" /></button></div>
                            {/* <form onSubmit={handleSubmit} className="axil-contact-form" id='header-start-project'>
                                    <div className="form-group">
                                        <label for="contact-name">Meno alebo Firma *</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="contact-name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-email">Email *</label>
                                        <input
                                        type="email"
                                        className="form-control"
                                        name="contact-email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-tel">Mobil *</label>
                                        <input
                                        type="tel"
                                        className="form-control"
                                        name="contact-tel"
                                        value={tel}
                                        onChange={(e) => setTel(e.target.value)}
                                        required
                                        />
                                    </div>
                                    <div className="form-group mb--40">
                                        <label for="contact-message">Špecifikácie (voliteľné)</label>
                                        <textarea
                                        name="contact-message"
                                        id="contact-message"
                                        className="form-control textarea"
                                        cols={30}
                                        rows={4}
                                        value={msg}
                                        onChange={(e) => setMsg(e.target.value)}
                                        />
                                    </div>
                                    {/* <div className='approval mb--40'>
                                      Odoslaním tohoto formuláru súhlasíte so <a href='https://ec.europa.eu/info/law/law-topic/data-protection_en'>spracovaním osobných údajov </a> v súlade s <a href='/'>obchodnými podmienkami</a> SIEDwebs
                                    </div> */}
                                    {/*<div className="form-group">
                                        <button
                                        type="submit"
                                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                        name="submit-btn"
                                        aria-label="Odoslať formulár"
                                        >
                                        {submitButtonText}
                                        </button>
                                    </div>
                            </form> */}
                            <InlineWidget url="https://calendly.com/hannahsied/siedwebs-initial-call?hide_gdpr_banner=1&primary_color=acc507" />
                        </div>
                        <div onClick={toggle} className={`backdrop fade ${isActive ? "show" : ""}`}></div>
                    </div>

                    <div className={`form-modal`}>
                      <div className={`form-modal-body ${isModalActive ? "show" : ""}`}>
                        {formSucc ? 
                          <>
                            <i class="fa fa-check succ"></i>
                            <h3>Odoslané!</h3>
                            <p>Ďakujeme Vám za dôveru.</p>
                          </>
                          :
                          <>
                            <i class="fa fa-times fail"></i>
                            <h3 class="text-center">Nastala<br />Chyba!</h3>
                            <p>Prosím skúste znovu...</p>
                          </>
                        }
                        
                        <span onClick={toggleModal} className='axil-btn btn-fill-primary btn-primary'>Zavrieť</span>
                      </div>
                      <div onClick={toggleModal} className={`backdrop fade ${isModalActive ? "show" : ""}`}></div>
                    </div>
        </header>
  </>;
}
