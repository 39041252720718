import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers';
import React, { Component } from 'react'
import TagManager from 'react-gtm-module/dist/TagManager';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import sal from 'sal.js';

import BrandsCarousel from '../components/BrandsCarousel';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.bodyOverflow = this.bodyOverflow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.state = {
          VizSensorActive: true,
          isModalActive: false,
          formSucc: true,
          submitButtonText: "Leave a message",
          name: '',
          email: '',
          company: '',
          msg: '',
        };
    
      }

    componentDidMount() {
        sal({
          threshold: 0.1,
          once: true
        });
        document.addEventListener("keydown", this.escFunction, false);
        
        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'pageview',
        //     page: {
        //         url: "/kontakt",
        //         title: "kontakt"
        //     }
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
    };
  
    componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction(event) {
        if (event.key === "Escape") {
          this.setState({isModalActive: false});
          this.bodyOverflow(false);
        }
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        let name = this.state.name;
        let email = this.state.email;
        let company = this.state.company;
        let msg = this.state.msg;
        const inputs = { name, email, company, msg };
        this.setState({submitButtonText: 'Sending...'});
        fetch('https://be.siedwebs.sk/api/send-contact', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
            this.setState({submitButtonText: 'Leave a message', active: false, name: '', email: '', company: '', msg: ''});
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
            this.bodyOverflow(true);
        })
        fetch('https://be.siedwebs.sk/api/en-send-contact-reply', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
            this.setState({submitButtonText: 'Leave a message', active: false, name: '', email: '', company: '', msg: ''});
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
            this.setState({isModalActive: true});
            this.bodyOverflow(true);
        })
        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'contactForm',
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
      }
    
    bodyOverflow(bool) {
        if(bool) {
            document.querySelector("body").style.overflow = 'hidden';
        } else {
            document.querySelector('body').removeAttribute("style");
        }
    }

    render() {
        return (
            <>
                <Helmet prioritizeSeoTags>
                    <title>SIEDwebs | Contact us</title>
                    <meta name="description" content="Contact us, so we can get back to you as soon as possible." />
                    <link rel="canonical" href='/contact' />

                    <meta property="og:url" content="https://siedwebs.com/contact" />
                    <meta property="og:title" content="SIEDwebs | Contact us" />
                    <meta property="og:description" content="Contact us, so we can get back to you as soon as possible." />

                    <meta property="twitter:url" content="https://siedwebs.com/contact" />
                    <meta name="twitter:title" content="SIEDwebs | Contact us" />
                    <meta name="twitter:description" content="Contact us, so we can get back to you as soon as possible." />
                </Helmet>
                {/*=====================================*/}
                {/*=       Breadcrumb Area Start       =*/}
                {/*=====================================*/}
                <div className="breadcrum-area">
                    <div className="container">
                    <div className="breadcrumb">
                        <ul className="list-unstyled">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li className="active">Contact</li>
                        </ul>
                        <h2 className="title h2">Contact</h2>
                    </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/bubble-17.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/line-4.png" alt="Line" />
                    </li>
                    </ul>
                </div>
                {/*=====================================*/}
                {/*=       Contact  Area Start        =*/}
                {/*=====================================*/}
                <section className="section section-padding">
                    <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                        <div className="contact-form-box shadow-box mb--30">
                            <h1 className="title h3">Get in touch – ask us anything.</h1>
                            <form onSubmit={this.handleSubmit} className="axil-contact-form">
                            <div className="form-group">
                                <label for="contact-name">Name *</label>
                                <input
                                type="text"
                                className="form-control"
                                name="contact-name"
                                required 
                                value={this.state.name}
                                onChange={(e) => this.setState({name: e.target.value})}
                                />
                            </div>
                            <div className="form-group">
                                <label for="contact-email">Email *</label>
                                <input
                                type="email"
                                className="form-control"
                                name="contact-email"
                                value={this.state.email}
                                required onChange={(e) => this.setState({email: e.target.value})}
                                />
                            </div>
                            <div className="form-group">
                                <label for="contact-company">Company (optional)</label>
                                <input
                                type="text"
                                className="form-control"
                                name="contact-company"
                                value={this.state.company}
                                onChange={(e) => this.setState({company: e.target.value})}
                                />
                            </div>
                            <div className="form-group mb--40">
                                <label for="contact-message">Message</label>
                                <textarea
                                name="contact-message"
                                id="contact-message"
                                className="form-control textarea"
                                cols={30}
                                rows={4}
                                value={this.state.msg}
                                onChange={(e) => this.setState({msg: e.target.value})}
                                />
                            </div>
                            <div className="form-group">
                                <button
                                type="submit"
                                className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                name="submit-btn"
                                aria-label="Leave a message"
                                >
                                {this.state.submitButtonText}
                                </button>
                            </div>
                            </form>
                        </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                            <h2 className="title h4">Phone</h2>
                            <p>We’re here for you 24/7. If you catch us on another line or aren’t able to reach us immediately, rest assured we’ll get back to you as soon as possible.</p>
                            <h3 className="phone-number h4">
                            <a href="tel:+17708724985">+1&nbsp;770&nbsp;872&nbsp;4985</a>
                            </h3>
                        </div>
                        <div className="contact-info mb--30">
                            <h2 className="title h4">E-Mail</h2>
                            <p>
                            Write us directly – we’ll get back to you in no more than 24 hours.
                            </p>
                            <h3 className="phone-number h4">
                            <a href="mailto:info@siedwebs.com">info@siedwebs.com</a>
                            </h3>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1">
                        <img loading="lazy" height="" width=""src="assets/media/others/bubble-2.png" alt="Bubble" />
                    </li>
                    <li className="shape shape-2">
                        <img loading="lazy" height="" width=""src="assets/media/others/bubble-1.png" alt="Bubble" />
                    </li>
                    <li className="shape shape-3">
                        <img loading="lazy" height="" width=""src="assets/media/others/circle-3.png" alt="Circle" />
                    </li>
                    </ul> */}
                </section>
                {/* <BrandsCarousel /> */}

                <div className={`form-modal`}>
                      <div className={`form-modal-body ${this.state.isModalActive ? "show" : ""}`}>
                        {this.state.formSucc ? 
                          <>
                            <i class="fa fa-check succ"></i>
                            <h3>Thank you!</h3>
                            <p>Your message has been sent.</p>
                          </>
                          :
                          <>
                            <i class="fa fa-times fail"></i>
                            <h3 class="text-center">Nastala<br />Chyba!</h3>
                            <p>Prosím skúste znovu...</p>
                          </>
                        }
                        
                        <span onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className='axil-btn btn-fill-primary btn-primary'>Zavrieť</span>
                      </div>
                      <div onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className={`backdrop fade ${this.state.isModalActive ? "show" : ""}`}></div>
                    </div>
                {/*=====================================*/}
                {/*=       Location  Area Start        =*/}
                {/*=====================================*/}
                {/* <section className="section section-padding bg-color-dark overflow-hidden">
                    <div className="container">
                    <div className="section-heading heading-light-left">
                        <span className="subtitle">Find Us</span>
                        <h2 className="title">Our office</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                        <div className="office-location">
                            <div className="thumbnail">
                            <img loading="lazy" height="" width=""src="assets/media/others/location-1.png" alt="Office" />
                            </div>
                            <div className="content">
                            <h4 className="title">Virginia-HQ</h4>
                            <p>435 Pouros Locks United States</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                        <div className="office-location">
                            <div className="thumbnail">
                            <img loading="lazy" height="" width=""src="assets/media/others/location-2.png" alt="Office" />
                            </div>
                            <div className="content">
                            <h4 className="title">Nevada</h4>
                            <p>46 Watsica Creek Suite 071 United States</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                        <div className="office-location">
                            <div className="thumbnail">
                            <img loading="lazy" height="" width=""src="assets/media/others/location-3.png" alt="Office" />
                            </div>
                            <div className="content">
                            <h4 className="title">Columbia</h4>
                            <p>7140 Wehner Tunnel Washington, D.C</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                        <div className="office-location">
                            <div className="thumbnail">
                            <img loading="lazy" height="" width=""src="assets/media/others/location-4.png" alt="Office" />
                            </div>
                            <div className="content">
                            <h4 className="title">New Mexico</h4>
                            <p>10 Maggie Valleys United States</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <ul className="shape-group-11 list-unstyled">
                    <li className="shape shape-1">
                        <img loading="lazy" height="" width=""src="assets/media/others/line-6.png" alt="line" />
                    </li>
                    <li className="shape shape-2">
                        <img loading="lazy" height="" width=""src="assets/media/others/circle-3.png" alt="line" />
                    </li>
                    </ul>
                </section> */}
                </>

      )
    }
}

export { Contact }
