
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import sal from 'sal.js';

//components
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from "react-countup";
import TagManager from 'react-gtm-module/dist/TagManager';
import { Helmet } from 'react-helmet-async';

import Reviews from '../components/Reviews';
import FeaturedCarousel from '../components/FeaturedCarousel';
import { InlineWidget } from 'react-calendly';
import TypeWriterEffect from 'react-typewriter-effect';

class Home extends Component {
  constructor(props) {
    super(props);
    this.bodyOverflow = this.bodyOverflow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      VizSensorActive: true,
      isModalActive: false,
      formSucc: true,
      active: false,
      submitButtonText: "Spojme sa",
      name: '',
      email: '',
      tel: '',
      msg: '',
    };

    this.changeVisibilityState = this.changeVisibilityState.bind(this);
  }

  componentDidMount() {
    sal({
      threshold: 0.1,
      once: true
    });
    document.addEventListener("keydown", this.escFunction, false);

    // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
    //     const tagManagerArgs = {
    //     dataLayer: {
    //         'js': new Date(), // current date time,
    //         event: 'pageview',
    //         page: {
    //             url: "/",
    //             title: "domov"
    //         }
    //     }
    //     }
    //     TagManager.dataLayer(tagManagerArgs);
  };

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  changeVisibilityState(isVisible) {
    if(isVisible)
      this.setState({ VizSensorActive: false });
  };

  escFunction(event) {
    if (event.key === "Escape") {
      this.setState({isModalActive: false});
      this.bodyOverflow(false);
    }
}

handleSubmit = (e) => {
    e.preventDefault();
    let name = this.state.name;
    let email = this.state.email;
    let tel = this.state.tel;
    let msg = this.state.msg;
    const inputs = { name, email, tel, msg };
    this.setState({submitButtonText: 'Odosiela sa...'});
    fetch('https://be.siedwebs.sk/api/send-cta', {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(inputs)
    }).then((response) => {
        this.setState({submitButtonText: 'Spojme sa', active: false, name: '', email: '', tel: '', msg: ''});
      if(response.ok) {
        this.setState({formSucc: true});
    } else {
        this.setState({formSucc: false});
    }
        this.bodyOverflow(true);
    })
    fetch('https://be.siedwebs.sk/api/send-cta-reply', {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(inputs)
    }).then((response) => {
        this.setState({submitButtonText: 'Spojme sa', active: false, name: '', email: '', tel: '', msg: ''});
      if(response.ok) {
        this.setState({formSucc: true});
    } else {
        this.setState({formSucc: false});
    }
        this.setState({isModalActive: true});
        this.bodyOverflow(true);
    })
    // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
    // const tagManagerArgs = {
    //   dataLayer: {
    //       'js': new Date(), // current date time,
    //       event: 'homeConsultation',
    //   }
    // }
    // TagManager.dataLayer(tagManagerArgs);
  }

bodyOverflow(bool) {
    if(bool) {
        document.querySelector("body").style.overflow = 'hidden';
    } else {
        document.querySelector('body').removeAttribute("style");
    }
}
  render() {
    return (
      <div>
        <Helmet prioritizeSeoTags>
          <title>SIEDwebs | Your Partner for Digital Solutions</title>
          <meta name="description" content="Modern and innovative web-, software- and digital-based solutions – customized for your company." />
          <link rel="canonical" href='/' />

          <meta property="og:url" content="https://siedwebs.com/" />
          <meta property="og:title" content="SIEDwebs | Your Partner for Digital Solutions" />
          <meta property="og:description" content="Modern and innovative web-, software- and digital-based solutions – customized for your company." />

          <meta property="twitter:url" content="https://siedwebs.com/" />
          <meta name="twitter:title" content="SIEDwebs | Your Partner for Digital Solutions" />
          <meta name="twitter:description" content="Modern and innovative web-, software- and digital-based solutions – customized for your company." />
        </Helmet>
        {/*=====================================*/}
        {/*=        Banner Area Start         =*/}
        {/*=====================================*/}
        <section className="banner banner-style-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12">
                <div className="banner-content">
                  <h1 className="title" data-sal="slide-up" data-sal-duration={1000} data-sal-delay={200}>New-Age Business Solutions</h1>
                  <div data-sal="slide-up" data-sal-duration={1000} data-sal-delay={100}>
                    <div className='banner-text'>
                      <div className='static-text'>in the form of</div> 
                    <TypeWriterEffect
                      textStyle={{
                        fontFamily: 'Poppins',
                        color: '#ffffff',
                        fontWeight: 500,
                        fontSize: '30px',
                      }}
                      startDelay={0}
                      cursorColor="#ffffff"
                      multiText={[
                        'websites',
                        'landing pages',
                        'eCommerce',
                        'web applications',
                        'software',
                        'mobile apps',
                      ]}
                      multiTextDelay={2000}
                      multiTextLoop={true}
                      typeSpeed={30}
                    />
                    </div>
                  </div>
                  <div className="banner-btn" data-sal="slide-up" data-sal-duration={1000} data-sal-delay={100}>
                      <span className="axil-btn btn-fill-white green-hover" onClick={() => {this.setState({active: true, product: "Biznis"}); this.bodyOverflow(true)}}>Book Demo</span>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 col-xl-5" data-sal="slide-up" data-sal-duration={1000} data-sal-delay={100}>
                <div className="banner-form">
                  <div className="contact-form-box shadow-box">
                    <h2 className="title">Získajte konzultáciu zdarma - posuňte svoj podnik vpred.</h2>
                    <form onSubmit={this.handleSubmit} className="axil-contact-form">
                      <div className="form-group">
                        <label for="contact-name">Meno *</label>
                        <input type="text" className="form-control" name="contact-name" placeholder="Ján Novák" required 
                        value={this.state.name}
                        onChange={(e) => this.setState({name: e.target.value})}/>
                      </div>
                      <div className="form-group">
                        <label for="contact-email">Email *</label>
                        <input type="email" className="form-control" name="contact-email" placeholder="príklad@mail.sk" 
                        value={this.state.email}
                        required onChange={(e) => this.setState({email: e.target.value})}/>
                      </div>
                      <div className="form-group mb--40">
                        <label for="contact-company">Mobil *</label>
                        <input type="tel" className="form-control" name="contact-company" placeholder={+421900000000} required 
                        value={this.state.tel}
                        onChange={(e) => this.setState({tel: e.target.value})}/>
                      </div>
                      <div className="form-group">
                        <button aria-label="Odoslať formulár" type="submit" className="axil-btn btn-borderd btn-fluid btn-primary" name="submit-btn">{this.state.submitButtonText}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className='featured-wrapper'>
              <div className='featured-title'>
                  <span className='featured-title'>As Seen On ...</span>  
              </div>
              <div className='featured-logos'>
                  <img loading="lazy" height="" width="" className='featured-logo' src='assets/media/sied/nbc.png' alt='' />                
                  <img loading="lazy" height="" width="" className='featured-logo' src='assets/media/sied/pittsburgh.png' alt='' />                
                  <img loading="lazy" height="" width="" className='featured-logo' src='assets/media/sied/usa.png' alt='' />                
                  <img loading="lazy" height="" width="" className='featured-logo' src='assets/media/sied/cbs.png' alt='' />                
                  <img loading="lazy" height="" width="" className='featured-logo' src='assets/media/sied/Fox-News.png' alt='' />       
              </div>
          </div>
        </section>     
        {/*=====================================*/}
        {/*=        Service Area Start         =*/}
        {/*=====================================*/}
        <section className="section section-padding">
          <div className="container">
            <div className="section-heading heading-left mb--20 mb_md--70">
              <span className="subtitle">A-to-Z Development</span>
              <h2 className="title">Complete Digital Solutions <br /> To Jumpstart Your Business</h2>
              <p>We build, optimise, and convert businesses on the internet by pairing the latest technologies with unmatched international talent – ensuring the best outcome for our clients.</p>
            </div>
            <div className="row">
              <div className="col-lg-4 mt--200 mt_md--0" data-sal="slide-up" data-sal-duration={800} data-sal-delay={100}>
                <div className="services-grid service-style-2">
                  <div className="thumbnail">
                    <img loading="lazy" height="" width=""src="assets/media/sied/dizajn.webp" alt="icon" />
                  </div>
                  <div className="content">
                    {/* <h5 className="title"> <a href="service-design.html">UI/UX Dizajn</a></h5> */}
                    <h3 className="title">UI-/UX-Design</h3>
                    <p>Our team of expert graphic designers and artists are dedicated to delivering stunning, user-friendly interfaces that give your customers the perfect online experience.<span className='invisible'>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span></p>
                    {/* <a href="service-design.html" className="more-btn">Find out more</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt--100 mt_md--0" data-sal="slide-up" data-sal-duration={800} data-sal-delay={200}>
                <div className="services-grid service-style-2 ">
                  <div className="thumbnail">
                    <img loading="lazy" height="" width=""src="assets/media/sied/development.webp" alt="icon" />
                  </div>
                  <div className="content">
                    <h3 className="title">Web Development & Software Solutions</h3>
                    <p>Our innovative, in-house, proprietary development processes and solutions led by industry veterans guarantee simplicity, accessibility, and speed at scale for businesses of all sizes. <span className='invisible'>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span></p>
                    {/* <a href="service-development.html" className="more-btn">Find out more</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4" data-sal="slide-up" data-sal-duration={800} data-sal-delay={300}>
                <div className="services-grid service-style-2">
                  <div className="thumbnail">
                    <img loading="lazy" height="" width=""src="assets/media/sied/optimalizacia.webp" alt="icon" />
                  </div>
                  <div className="content">
                    <h3 className="title">Optimised For Success</h3>
                    <p>Our experience in growing bespoke brands on a global scale at SIED Media gives our clients a seemingly unfair edge over their competition as we use real-time data to improve website conversion rates and traffic, predictably.<span className='invisible'>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</span></p>
                    {/* <a href="service-content-strategy.html" className="more-btn">Find out more</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className='row logos'>
              <div className="d-block d-lg-none">
                <div className="logos-heading section-heading heading-left" data-sal="slide-up" data-sal-duration={800} data-sal-delay={100}>
                  <span className="subtitle">The Real Full-Stack</span>
                  <h2>We Don’t Just “Build Websites”</h2>
                  <p className="mb-2">
                  Unlike most tech companies out there, our diverse tech stack allows us to develop solutions that are in alignment with what will bring you, our client, the best overall results, instead of just offering you what ‘fits us best’.
                  </p>
                  <p>
                  Through in-depth analysis and comparison, we help you make educated decisions on what’s best for your company by taking into account the current scale and direction of your business, as well as your budget.
                  </p>
                </div>
              </div>
              <div className='col-lg-6 my-auto'>
                <div className='logos-grid'>
                  <img loading="lazy" height="" width=""src='assets/media/sied/laravel.png' alt='' data-sal="slide-up" data-sal-duration={1000} data-sal-delay={100} />
                  <img loading="lazy" height="" width=""src='assets/media/sied/react.png' alt='' data-sal="slide-up" data-sal-duration={1000} data-sal-delay={200} />
                  <img loading="lazy" height="" width=""src='assets/media/sied/github.png' alt='' data-sal="slide-up" data-sal-duration={1000} data-sal-delay={300} />
                  <img loading="lazy" height="" width=""src='assets/media/sied/figma2.png' alt='' data-sal="slide-up" data-sal-duration={1000} data-sal-delay={400} />
                  <img loading="lazy" height="" width=""src='assets/media/sied/wordpress.png' alt='' data-sal="slide-up" data-sal-duration={1000} data-sal-delay={500} />
                  <img loading="lazy" height="" width=""src='assets/media/sied/shopify.png' alt='' data-sal="slide-up" data-sal-duration={1000} data-sal-delay={600} />
                  <img loading="lazy" height="" width=""src='assets/media/sied/adobe.png' alt='' data-sal="slide-up" data-sal-duration={1000} data-sal-delay={700} />
                  <img loading="lazy" height="" width=""src='assets/media/sied/google.png' alt='' data-sal="slide-up" data-sal-duration={1000} data-sal-delay={800} />
                  <img loading="lazy" height="" width=""src='assets/media/sied/webflow.png' alt='' data-sal="slide-up" data-sal-duration={1000} data-sal-delay={900} />
                </div>
              </div>
              <div className="col-lg-6 d-none d-lg-block">
                <div className="logos-heading section-heading heading-right" data-sal="slide-up" data-sal-duration={800} data-sal-delay={100}>
                  <span className="subtitle">The Real Full-Stack</span>
                  <h2>We Don’t Just “Build Websites”</h2>
                  <p className="mb-2">
                  Unlike most tech companies out there, our diverse tech stack allows us to develop solutions that are in alignment with what will bring you, our client, the best overall results, instead of just offering you what ‘fits us best’.
                  </p>
                  <p>
                  Through in-depth analysis and comparison, we help you make educated decisions on what’s best for your company by taking into account the current scale and direction of your business, as well as your budget.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-1"><img loading="lazy" height="" width=""src="assets/media/others/circle-2.png" alt="circle" /></li>
            <li className="shape shape-2"><img loading="lazy" height="" width=""src="assets/media/others/bubble-2.png" alt="Line" /></li>
            <li className="shape shape-3"><img loading="lazy" height="" width=""src="assets/media/others/bubble-1.png" alt="Line" /></li>
          </ul> */}
        </section>
        {/*=====================================*/}
        {/*=        Project Area Start         =*/}
        {/*=====================================*/}
        {/*
        <section className="section section-padding-2 bg-color-light">
          <div className="container">
            <div className="section-heading heading-left">
              <span className="subtitle">Our Project</span>
              <h2 className="title">Some of our <br /> finest work.</h2>
            </div>
            <div className="axil-isotope-wrapper">
              {/* <div className="isotope-button isotope-project-btn">
                <button data-filter="*" className="is-checked"><span className="filter-text">All Works</span></button>
                <button data-filter=".branding"><span className="filter-text">Branding</span></button>
                <button data-filter=".mobile"><span className="filter-text">Mobile</span></button>
              </div> */}
              {/*
              <div className="row row-35 isotope-list">
                <div className="col-md-6 project branding">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="single-portfolio.html">
                        <img loading="lazy" height="" width=""src="assets/media/project/project-1.png" alt="project" />
                      </a>
                    </div>
                    <div className="content">
                      <h4 className="title"><a href="single-portfolio.html">Creative Agency</a></h4>
                      <span className="subtitle">Full Branding, Website, App</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 project mobile">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="single-portfolio.html">
                        <img loading="lazy" height="" width=""src="assets/media/project/project-2.png" alt="project" />
                      </a>
                    </div>
                    <div className="content">
                      <h4 className="title"><a href="single-portfolio.html">Digital Marketing</a></h4>
                      <span className="subtitle">Logo, Website &amp; Mobile App</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 project branding">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="single-portfolio.html">
                        <img loading="lazy" height="" width=""src="assets/media/project/project-3.png" alt="project" />
                      </a>
                    </div>
                    <div className="content">
                      <h4 className="title"><a href="single-portfolio.html">Digital Agency</a></h4>
                      <span className="subtitle">Website, UI/UX</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 project mobile">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="single-portfolio.html">
                        <img loading="lazy" height="" width=""src="assets/media/project/project-4.png" alt="project" />
                      </a>
                    </div>
                    <div className="content">
                      <h4 className="title"><a href="single-portfolio.html">Social Engagement</a></h4>
                      <span className="subtitle">Branding, Website, IOS App</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 project branding">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="single-portfolio.html">
                        <img loading="lazy" height="" width=""src="assets/media/project/project-5.png" alt="project" />
                      </a>
                    </div>
                    <div className="content">
                      <h4 className="title"><a href="single-portfolio.html">Plan Management</a></h4>
                      <span className="subtitle">Design, Development</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 project mobile">
                  <div className="project-grid">
                    <div className="thumbnail">
                      <a href="single-portfolio.html">
                        <img loading="lazy" height="" width=""src="assets/media/project/project-6.png" alt="project" />
                      </a>
                    </div>
                    <div className="content">
                      <h4 className="title"><a href="single-portfolio.html">Web Application</a></h4>
                      <span className="subtitle">Logo, Webapp, App</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-1"><img loading="lazy" height="" width=""src="assets/media/others/circle-2.png" alt="circle" /></li>
            <li className="shape shape-2"><img loading="lazy" height="" width=""src="assets/media/others/bubble-2.png" alt="Line" /></li>
            <li className="shape shape-3"><img loading="lazy" height="" width=""src="assets/media/others/bubble-1.png" alt="Line" /></li>
          </ul>
        </section>
        {/*=====================================*/}
        {/*=        Counter Up Area Start      =*/}
        {/*=====================================*/}
        <section className="section section-padding bg-color-dark">
          <div className="container">
            <div className="section-heading heading-light">
              <span className="subtitle">Striving For Greatness</span>
              <h2 className="title">#1 Team Of Professionals</h2>
              <p>Our passion lies in helping companies succeed in a digitally driven age.</p>
            </div>
            <div className="row">
              <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration={800} data-sal-delay={100}>
                <div className="counterup-progress ">
                  <div className="icon">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      viewBox="0 0 500 500" fill="currentColor" class="svgIcon">
                    <g>
                      <path class="st0" d="M452.9,290.7l-27.1-60.2c-2.1-3.6-3.1-7.7-3.6-11.8c-6.6-72.7-62.6-131-134.6-140.1c-2.2-0.3-4.2,1.5-4.2,3.7
                        c0.1,14-5.9,27.6-16.7,37l-24.5,20.7c-1.1,0.9-1.5,2.3-1.2,3.7l7.7,31.2c3.6,14.4,0,29.4-9.3,41.2c-9.3,11.3-23.2,18.5-38.1,18.5
                        c-8.8,0-17.5-2.1-25.2-6.7l-27.4-16.8c-1.2-0.7-2.7-0.7-3.9,0l-26.9,16.8c-0.5,0.5-1.2,0.9-1.9,1.2c-1.4,0.6-2.3,1.9-2.3,3.4v0
                        c0,51,24.2,100.4,62.3,132.8c4.6,4.1,7.2,9.3,7.2,15.4v88.6c0,7.2,5.7,12.9,12.4,13.4L329,494.1c8.2,0.5,14.9-5.7,14.9-13.4l0-62.8
                        c0-4.1,3.6-7.2,7.2-7.2h46.3c14.4,0,25.7-11.8,25.7-25.7v-62.8c0-2.6,2.1-5.2,5.2-5.2h10.1C452.4,317.5,457.8,302.3,452.9,290.7
                        L452.9,290.7z"/>
                      <path class="st0" d="M155.5,174.9l39.1,23.7c10.8,6.7,24.7-3.1,21.6-15.4l-10.8-44.3c-1-5.2,0.5-10.8,4.6-14.4l35-29.9
                        c9.8-8.2,4.6-24.2-8.2-25.2L191,65.7c-5.2-0.5-10.3-3.6-12.4-8.8l-17.5-42.2c-5.2-11.8-21.6-11.8-26.8,0L116.8,57
                        c-2.1,5.2-6.7,8.2-12.4,8.8l-45.8,3.6c-12.9,0.5-18,16.5-8.2,24.7l35,29.9c4.1,3.6,5.7,9.3,4.6,14.4l-10.8,44.8
                        c-3.1,12.4,10.8,22.1,21.6,15.4l39.1-23.7C145.2,172.3,150.8,172.3,155.5,174.9L155.5,174.9z"/>
                    </g>
                    </svg>
                  </div>
                  <div className="count-number h2">
                    {/* <span className="number count" data-count={15}>15</span> */}
                    <CountUp className="number count" end={8} redraw={true} onStart={() => this.changeVisibilityState}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={this.changeVisibilityState} delayedCall active={this.state.VizSensorActive}>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="symbol">+</span>
                  </div>
                  <h3 className="title">years of experience</h3>
                </div>
              </div>
              <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration={800} data-sal-delay={200}>
                <div className="counterup-progress">
                  <div className="icon">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      viewBox="0 0 500 500" fill="currentColor" class="svgIcon">
                    <path class="st0" d="M64.5,218.8l58.2,24.1h0c-6.6,16.6-11.8,33.6-15.7,51.1l-2.2,10l83.5,83.5l10-2.2v0
                      c17.4-3.9,34.5-9.1,51.1-15.7l24.1,58.2l0,0c0.5,1.2,1.5,2,2.8,2.3c1.2,0.2,2.5-0.2,3.4-1.1l36.5-36.5h0c12-12,18.4-28.6,17.6-45.6
                      l-1.3-23.4c67.6-49.9,135.9-137.4,154.6-290.1c1.1-7.8-1.5-15.6-7.1-21.1c-5.5-5.6-13.3-8.2-21.1-7.2
                      C306.3,24,218.7,92.4,168.8,159.8l-23.4-1.2c-17-0.9-33.5,5.4-45.6,17.3l-36.5,36.5c-1,0.9-1.5,2.2-1.3,3.6
                      C62.3,217.3,63.2,218.4,64.5,218.8L64.5,218.8z M298.6,129.9c8.5-8.5,20-13.2,31.9-13.2c12,0,23.5,4.8,31.9,13.3s13.2,20,13.2,31.9
                      c0,12-4.8,23.5-13.2,31.9c-8.5,8.5-19.9,13.2-31.9,13.3c-12,0-23.5-4.7-31.9-13.2c-8.5-8.5-13.3-20-13.3-32
                      C285.3,149.9,290.1,138.4,298.6,129.9z M49.4,392c-9.7-3.4-20-4.2-30.1-2.4c-1.8,0.4-3.7-0.2-5-1.5c-1.6-1.6-2.1-4.1-1.1-6.2
                      c10.7-23,36.4-72,87.8-34.7c0.5,0.5,0.9,1.2,0.9,1.9c0,0.7-0.3,1.4-0.9,1.9c-13.9,11-19.2,30.7-18.6,48.5c0.1,2.1,1.8,3.8,3.9,3.9
                      c17.7,0.7,39.2-2.1,50.3-15.9c0.5-0.6,1.2-0.9,2-0.9c0.8,0,1.5,0.3,2,0.9c7.1,8.5,22.2,31.3,0.5,58.1h0
                      c-9.5,11.4-23.1,18.6-37.9,20.1c-21.1,2.3-60,9.1-76,27.3c-1.3,1.5-3.3,2.2-5.3,1.8c-2-0.4-3.6-1.9-4.1-3.8
                      C12.1,471.9,3.3,429,49.4,392L49.4,392z"/>
                    </svg>
                  </div>
                  <div className="count-number h2">
                    {/* <span className="number count" data-count={360}>360</span> */}
                    <CountUp className="number count" end={54} redraw={true} onStart={() => this.changeVisibilityState}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall active={this.state.VizSensorActive}>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="symbol"></span>
                  </div>
                  <h3 className="title">successful projects</h3>
                </div>
              </div>
              <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration={800} data-sal-delay={300}>
                <div className="counterup-progress">
                  <div className="icon">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      viewBox="0 0 500 500" fill="currentColor" class="svgIcon">
                    <path class="st0" d="M267.8,402.6l90.1,54.5c24.9,15.4,56.9-7.1,49.8-35.6l-24.9-101.9c-2.4-11.9,1.2-24.9,10.7-33.2l80.6-68.8
                      c22.5-19,10.7-55.7-19-58.1l-105.5-8.3c-11.9-1.2-23.7-8.3-28.5-20.2l-40.3-97.2C269,6.6,231,6.6,219.2,33.9l-40.3,97.2
                      c-4.7,11.9-15.4,19-28.5,20.2l-105.5,8.3c-29.6,1.2-41.5,37.9-19,56.9l80.6,68.8c9.5,8.3,13,21.3,10.7,33.2L92.3,421.5
                      c-7.1,28.5,24.9,51,49.8,35.6l90.1-54.5C244.1,396.6,257.1,396.6,267.8,402.6L267.8,402.6z"/>
                    </svg>
                  </div>
                  <div className="count-number h2">
                    {/* <span className="number count" data-count={600}>600</span> */}
                    <CountUp className="number count" end={100} redraw={true} onStart={() => this.changeVisibilityState}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall active={this.state.VizSensorActive}>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="symbol">%</span>
                  </div>
                  <h3 className="title">satisfaction rate</h3>
                </div>
              </div>
              <div className="col-lg-3 col-6" data-sal="slide-up" data-sal-duration={800} data-sal-delay={400}>
                <div className="counterup-progress">
                  <div className="icon">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      viewBox="0 0 500 500" fill="currentColor" class="svgIcon">
                    <path class="st0" d="M244.2,176.8v-42.4c-67.9-0.1-128.5,48.6-141.3,118.4c-12.7,68.9,25.1,136.9,89.9,161.7
                      c18.5,7.1,27.8,28,20.8,46.7c-6.2,16.6-23.3,25.9-40,22.8c-2.1-0.4-4.2-1-6.2-1.7c-97.2-37.2-154-139.2-135-242.6
                      C51.6,135.1,142.4,62.2,244.2,62.4V27.2c0-16.9,21.2-25.5,34.1-13.9l82.7,74.8c8.4,7.6,8.4,20.3,0,27.9l-82.7,74.8
                      C265.4,202.4,244.2,193.7,244.2,176.8 M291.6,415.7c-5.3,1.9-10.8,3.5-16.4,4.8c-19.3,4.3-31.6,23.7-27.3,43.2
                      c3.2,14.6,14.8,25.2,28.5,27.8c4.6,0.8,9.4,0.8,14.3-0.3c8.4-1.9,16.8-4.3,24.9-7.2c18.6-6.7,28.4-27.4,21.8-46.3
                      C330.7,418.8,310.2,409,291.6,415.7 M388.2,352.3c-19.8-0.5-36.3,15.9-36.8,36c-0.5,18,12.2,33,29.2,36.2c1.8,0.4,3.7,0.6,5.6,0.6
                      c19.8,0.6,36.3-15.5,36.9-35.4c0-0.1,0-0.8,0-0.8C423.6,368.7,407.9,352.6,388.2,352.3"/>
                    </svg>
                  </div>
                  <div className="count-number h2">
                    {/* <span className="number count" data-count={64}>64</span> */}
                    <CountUp className="number count" end={3} redraw={true} onStart={() => this.changeVisibilityState}>
                      {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall active={this.state.VizSensorActive}>
                              <span ref={countUpRef} />
                          </VisibilitySensor>
                      )}
                    </CountUp>
                    <span className="symbol"></span>
                  </div>
                  <h3 className="title">projects in progress</h3>
                </div>
              </div>
            </div>
          </div>
          {/* <ul className="list-unstyled shape-group-10">
            <li class="shape shape-1"><img loading="lazy" height="" width=""src="assets/media/others/line-9.png" alt="Circle"></li>
            <li className="shape shape-2"><img loading="lazy" height="" width=""src="assets/media/others/bubble-42.png" alt="Circle" /></li>
            <li className="shape shape-3"><img loading="lazy" height="" width=""src="assets/media/others/bubble-43.png" alt="Circle" /></li>
          </ul> */}
        </section>
        {/*=====================================*/}
        {/*=        Testimonial Area Start     =*/}
        {/*=====================================*/}
        
        <section className="section section-padding">
          <Reviews />
        </section>

        <div className={`pric-form`}>
                        <div className={`form-div ${this.state.active ? "show" : ""}`}>
                            <div onClick={() => {this.setState({active: false}); this.bodyOverflow(false)}} className={`pric-form-close ${this.state.active ? "show" : ""}`}><button aria-label="Zatvoriť formulár"><i className="fas fa-times" /></button></div>
                            {/* <form onSubmit={this.handleSubmit} className="axil-contact-form">
                            <div className="form-group">
                                        <label for="contact-name">Meno alebo Firma *</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="contact-name"
                                        value={this.state.name}
                                        onChange={(e) => this.setState({
                                            name: e.target.value})}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-email">Email *</label>
                                        <input
                                        type="email"
                                        className="form-control"
                                        name="contact-email"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({
                                            email: e.target.value})}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-tel">Mobil *</label>
                                        <input
                                        type="tel"
                                        className="form-control"
                                        name="contact-tel"
                                        value={this.state.tel}
                                        onChange={(e) => this.setState({
                                            tel: e.target.value})}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-company">Plán *</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="contact-company"
                                        readOnly
                                        value={this.state.product}
                                        />
                                    </div>
                                    <div className="form-group mb--40">
                                        <label for="contact-message">Špecifikácie (voliteľné)</label>
                                        <textarea
                                        name="contact-message"
                                        id="contact-message"
                                        className="form-control textarea"
                                        cols={30}
                                        rows={4}
                                        value={this.state.msg}
                                        onChange={(e) => this.setState({
                                            msg: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button
                                        type="submit"
                                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                        name="submit-btn"
                                        aria-label="Odoslať formulár"
                                        >
                                        {this.state.submitButtonText}
                                        </button>
                                    </div>
                            </form> */}
                            <InlineWidget url="https://calendly.com/hannahsied/siedwebs-initial-call?hide_gdpr_banner=1&primary_color=acc507" />
                        </div>
                        <div onClick={() => {this.setState({active: false}); this.bodyOverflow(false)}} className={`backdrop fade ${this.state.active ? "show" : ""}`}></div>
                    </div>
        <div className={`form-modal`}>
                      <div className={`form-modal-body ${this.state.isModalActive ? "show" : ""}`}>
                        {this.state.formSucc ? 
                          <>
                            <i class="fa fa-check succ"></i>
                            <h3>Odoslané!</h3>
                            <p>Ďakujeme Vám za dôveru.</p>
                          </>
                          :
                          <>
                            <i class="fa fa-times fail"></i>
                            <h3 class="text-center">Nastala<br />Chyba!</h3>
                            <p>Prosím skúste znovu...</p>
                          </>
                        }
                        
                        <span onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className='axil-btn btn-fill-primary btn-primary'>Zavrieť</span>
                      </div>
                      <div onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className={`backdrop fade ${this.state.isModalActive ? "show" : ""}`}></div>
                    </div>
        
    </div>
    )
  }
}

export { Home };
