import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers';
import React, { Component } from 'react'
import TagManager from 'react-gtm-module/dist/TagManager';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import sal from 'sal.js';

import BrandsCarousel from '../components/BrandsCarousel';

class Thanks extends Component {
    constructor(props) {
        super(props);
        this.bodyOverflow = this.bodyOverflow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.state = {
          VizSensorActive: true,
          isModalActive: false,
          formSucc: true,
          submitButtonText: "Nachricht abschicken",
          name: '',
          email: '',
          company: '',
          msg: '',
        };
    
      }

    componentDidMount() {
        sal({
          threshold: 0.1,
          once: true
        });
        document.addEventListener("keydown", this.escFunction, false);
        
        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'pageview',
        //     page: {
        //         url: "/kontakt",
        //         title: "kontakt"
        //     }
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
    };
  
    componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction(event) {
        if (event.key === "Escape") {
          this.setState({isModalActive: false});
          this.bodyOverflow(false);
        }
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        let name = this.state.name;
        let email = this.state.email;
        let company = this.state.company;
        let msg = this.state.msg;
        const inputs = { name, email, company, msg };
        this.setState({submitButtonText: 'Sie wird gesendet...'});
        fetch('https://be.siedwebs.sk/api/send-contact', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
            this.setState({submitButtonText: 'Nachricht abschicken', active: false, name: '', email: '', company: '', msg: ''});
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
            this.bodyOverflow(true);
        })
        fetch('https://be.siedwebs.sk/api/send-contact-reply', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
            this.setState({submitButtonText: 'Nachricht abschicken', active: false, name: '', email: '', company: '', msg: ''});
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
            this.setState({isModalActive: true});
            this.bodyOverflow(true);
        })
        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'contactForm',
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
      }
    
    bodyOverflow(bool) {
        if(bool) {
            document.querySelector("body").style.overflow = 'hidden';
        } else {
            document.querySelector('body').removeAttribute("style");
        }
    }

    render() {
        return (
            <>
                <Helmet prioritizeSeoTags>
                    <title>Thank you</title>
                    <meta name="description" content="Modern and innovative web-, software- and digital-based solutions – customized for your company." />
                    <link rel="canonical" href='/thank-you' />
                </Helmet>

                <section className="error-page onepage-screen-area">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div
                            className="content"
                            data-sal="slide-up"
                            data-sal-duration={800}
                            data-sal-delay={400}
                            >
                            <h2 className="title">Thank you.</h2>
                            <p>
                            Your booking has been submitted successfully – check your email for confirmation.
                            </p>
                            <a href="/" className="axil-btn btn-fill-primary">
                            Back to Home
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                            className="thumbnail"
                            data-sal="zoom-in"
                            data-sal-duration={800}
                            data-sal-delay={400}
                            >
                            <img src="assets/media/sied/thanks.png" alt={404} />
                            </div>
                        </div>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                        >
                        <img src="assets/media/others/bubble-9.png" alt="Bubble" />
                        </li>
                        <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                        >
                        <img src="assets/media/others/bubble-27.png" alt="Bubble" />
                        </li>
                        <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                        >
                        <img src="assets/media/others/line-4.png" alt="Line" />
                        </li>
                    </ul>
                </section>

                </>

      )
    }
}

export { Thanks }
